import { useQueryParams } from "@/utils/useQueryParams";
import { format } from "date-fns";
import { useState } from "react";
import Calendar from "react-calendar";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Text, Button } from "@/ui";
import qs from "query-string";
import { BillingViewWrapper } from "./data/BillingView";
import { RefundViewWrapper } from "./data/Refundview";
import { ReportVieWrapper } from "./data/ReportView";
import { BillingViewCpnWeb } from "./web/BillView";
import { RefundViewCpnWeb } from "./web/RefoundView";
import { ReportViewCpnWeb } from "./web/ReportView";

import { BillViewMobile } from "./mobile/BillView";
import { RefundViewMobileCpn } from "./mobile/RefoundView";
import { ReportViewMobile } from "./mobile/ReportView";

export function ReportView({ isMobile }) {
  return (
    <ReportVieWrapper>
      {/* @ts-ignore */}
      {isMobile ? <ReportViewMobile /> : <ReportViewCpnWeb />}
    </ReportVieWrapper>
  );
}

export function RefundView({ isMobile }) {
  return (
    <RefundViewWrapper>
      {/* @ts-ignore */}
      {isMobile ? <RefundViewMobileCpn /> : <RefundViewCpnWeb />}
    </RefundViewWrapper>
  );
}

export function BillingView({ isMobile }) {
  return (
    <BillingViewWrapper>
      {/* @ts-ignore */}
      {isMobile ? <BillViewMobile /> : <BillingViewCpnWeb />}
    </BillingViewWrapper>
  );
}

export const FilterPeriodBox = () => {
  const params = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [periodVisible, setPeriodVisible] = useState(false);
  //@ts-ignore
  const period = params.period ? new Date(Number(params.period)) : new Date();

  console.log({ period });

  return (
    <Box justifyContent="center" alignItems="center" width="100%" height="60px" position="relative">
      <Box justifyContent="flex-end" alignItems="center" width="100%" height="100%" position="relative">
        <Box justifyContent="center" alignItems="flex-end" pb={1} pr={3}>
          {/* FILTER PERIODE */}
          <Box style={{ position: "relative" }} flexDirection="column">
            <Text fontSize={12}>Période du :</Text>
            <Box
              p="2px"
              pl={1}
              pr={1}
              style={{
                borderRadius: 5,
                backgroundColor: "#fff",
                border: "solid 1px",
                borderColor: "#c7c7c7",
                cursor: "pointer",
                fontSize: 13,
                fontWeight: 600,
                maxHeight: "28px",
                minHeight: "28px",
                color: "#454545",
                width: 100,
              }}
              alignItems="center"
              onClick={() => setPeriodVisible(!periodVisible)}
            >
              {format(period, "MM/yyyy")}
            </Box>

            {periodVisible && (
              <Box
                style={{
                  position: "absolute",
                  top: "100%",
                  right: 0,
                  marginTop: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  boxShadow: "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
                  borderRadius: "5px",
                  overflow: "hidden",
                  zIndex: 99999,
                }}
              >
                <Calendar
                  maxDetail="year"
                  onChange={(c) => {
                    const p = new Date(c as any as string);
                    let to = qs.stringify({ ...params, period: p.getTime() }, { arrayFormat: "index" });
                    navigate(`${location.pathname}?${to}`, {
                      replace: true,
                    });
                    setPeriodVisible(false);
                  }}
                  maxDate={new Date()}
                  value={period}
                />
                <Box p={1}>
                  <Button onClick={() => setPeriodVisible(false)}>Fermer</Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
