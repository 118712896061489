import { Formik, Form } from "formik";
import { Box, Text, Button, Link } from "../../../ui";
import { Card } from "../../../ui/Card";
import { TextField } from "../../../ui/field/TextField";
import { LAST_CONNECTION } from "@/index";
import { useNavigate } from "react-router-dom";
import { setIdToken, setAccessToken, setRefreshToken } from "@/utils/localStorage";
import { useState } from "react";
import { fetchLogin } from "@/api/billing/fetchLogin";
import { useTheme } from "@mui/material";
import { useSystemContext } from "@/provider/common/SystemProvider";
import { UpdatePasswordError } from "@/utils/UpdatePasswordError";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function LoginView() {
  const [error, setError] = useState();
  const navigate = useNavigate();
  const theme = useTheme();
  const { setLoading, setUser } = useSystemContext();
  const { isMobile } = useSystemContext();

  return (
    <Box height="100vh" justifyContent="center" alignItems="center" width="100vw" style={{ backgroundColor: "#f1f1f1" }}>
      <div className="background-cloud height40" />
      <div className="background-over" />
      <div className="background-mountain-cofidoc" />
      <Box display="flex" p={2} alignItems="center" justifyContent="center" zIndex={2} position="absolute" top={20} left={20}>
        <div className="background-logo" />
      </Box>
      <Box
        width="100%"
        style={{
          position: "relative",
          zIndex: 2,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card justifyContent="center" alignItems="center" display="flex" flexDirection="column" width={isMobile ? "90%" : "650px"}>
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={async (values) => {
              console.log("=>  values:", values);
              try {
                setLoading(true);
                setError(null);
                const result = await fetchLogin({
                  email: values.email,
                  password: values.password,
                });
                console.log("result", result);
                localStorage.setItem(LAST_CONNECTION, new Date().toISOString());
                setIdToken(JSON.stringify(result.idToken));
                setAccessToken(JSON.stringify(result.token));
                setRefreshToken(JSON.stringify(result.refreshToken));
                if (typeof result.user == "object") {
                  setUser(JSON.stringify(result.user));
                }
                setLoading(false);
                navigate("/home");
                //automatique needed refresh to navigate
                navigate(0);
                return;
              } catch (e) {
                console.log("error", e instanceof UpdatePasswordError, e.message);
                if (e instanceof UpdatePasswordError) {
                  navigate("/create-password", {
                    state: {
                      password: values.password,
                      email: values.email,
                    },
                  });
                  //automatique needed refresh to navigate
                  navigate(0);
                } else {
                  setError(e?.message);
                  setLoading(false);
                }
              } finally {
                // setLoading(false);
              }
            }}
          >
            <Form
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box display="flex" width="100%" p={2}>
                <Link onClick={() => navigate("/welcome")}>
                  <ArrowBackIcon fontSize="small" />
                  <Box pr={1} />
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: 12,
                      fontStyle: "italic",
                    }}
                  >
                    Retour à l'accueil
                  </Text>
                </Link>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Box style={{ margin: 20 }}>
                  <Text fontSize={20} fontWeight={500} textAlign="center" color={theme.colors.primary}>
                    Je me connecte à mon compte
                  </Text>
                </Box>
              </Box>
              <Box flexDirection="column" width="80%">
                <TextField label="Email" name="email" width="100%" />
                <Box my={1} />
                <TextField type="password" label="Mot de passe" name="password" width="100%" />
                <Box justifyContent="center" alignItems="center" height={60}>
                  {error && (
                    <Box fontSize={11} color="#ff0000">
                      {error}
                    </Box>
                  )}
                </Box>
                <Box flex={1} justifyContent="center" mt={3}>
                  <Button type="submit">Connexion</Button>
                </Box>
                <Box justifyContent="center" alignItems="center" height={60}>
                  <Link onClick={() => navigate("/forgot-password")}>
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        textDecoration: "underline",
                      }}
                    >
                      J'ai oublié mon mot de passe
                    </Text>
                  </Link>
                </Box>
              </Box>
            </Form>
          </Formik>
        </Card>
      </Box>
      <Box style={{ position: "absolute", bottom: 0, zIndex: 3, padding: 5 }}>
        <Text style={{ textAlign: "center", fontSize: 12 }}>© 2024 Cofidoc Groupe</Text>
      </Box>
    </Box>
  );
}
