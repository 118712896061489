import { useNavigate, useLocation } from "react-router-dom";
import { Box, Button, Text, NavLink, Modal } from "../../ui";
import { fetchSetCurrentOffice } from "@/api/nurse/fetchSetCurrentOffice";
import qs from "query-string";
import { useTheme } from "@mui/material";
import { setIdToken, setAccessToken, setRefreshToken } from "@/utils/localStorage";
import { ReactComponent as SVGAccueil } from "../../images/accueil.svg";
import { ReactComponent as SVGGestion } from "../../images/gestion-cofidoc.svg";
import HandymanIcon from "@mui/icons-material/Handyman";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSystemContext } from "@/provider/common/SystemProvider";

export function LeftNavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [expandTools, setExpandTools] = useState(true);

  const navigationAccount = () => {
    const period = new Date(new Date().setDate(new Date().getDate()));
    period.setHours(0, 0);
    let to = qs.stringify({ period: period.getTime() }, { arrayFormat: "index" });
    navigate(`/account?${to}`);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="170px"
      height="100%"
      zIndex={100}
      className="overBg"
      justifyContent="space-between"
      alignItems="center"
      p={3}
      pt="20px"
      pb="20px"
    >
      <Box display="flex" flexDirection="column" alignItems="center" width="100%">
        <div className="logo-cofidoc" />
        <Box height="1px" width="100%" bgcolor="#d5d5d5" />
        <NavLink onClick={() => navigate("/home")} selected={location.pathname.includes("home")}>
          <SVGAccueil
            style={{
              width: "25px",
              height: "25px",
              color: location.pathname.includes("home") ? theme.palette.primary.main : "#6a6a6a",
            }}
          />
          <Box p={1} />
          <Text m={0}>Accueil</Text>
        </NavLink>
        <Box height="1px" width="100%" bgcolor="#d5d5d5" />
        <NavLink onClick={navigationAccount} selected={location.pathname.includes("account")}>
          <SVGGestion
            style={{
              width: "25px",
              height: "25px",
              color: location.pathname.includes("account") ? theme.palette.primary.main : "#6a6a6a",
            }}
          />
          <Box p={1} />
          <Text m={0}>Gestion Cofidoc</Text>
        </NavLink>
        <Box height="1px" width="100%" bgcolor="#d5d5d5" />
        <NavLink selected={location.pathname.includes("tools")}>
          <Box
            onClick={() => setExpandTools(!expandTools)}
            position="absolute"
            right={-5}
            top={50}
            style={{
              color: theme.palette.primary.main,
              transform: expandTools ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform ease 300ms",
            }}
          >
            <ExpandMoreIcon />
          </Box>
          <Box flexDirection="column" justifyContent="center" alignItems="center" height="100px" onClick={() => setExpandTools(!expandTools)}>
            <Box flexDirection="column" justifyContent="center" alignItems="center">
              <HandymanIcon
                style={{
                  width: "25px",
                  height: "25px",
                  color: location.pathname.includes("tools") ? theme.palette.primary.main : "#6a6a6a",
                }}
              />
              <Box p={1} />
              <Text m={0}>Outils Cofidoc</Text>
            </Box>
          </Box>
          <Box
            flexDirection="column"
            alignItems="flex-end"
            height={expandTools ? "100px" : "0px"}
            style={{ transition: "height ease 300ms" }}
            overflow="hidden"
            width="100%"
          >
            <Box height="30px" width="100%" minHeight="30px" justifyContent="flex-end" pl={3} onClick={() => navigate("/tools/mobility")}>
              <Text
                textAlign="right"
                fontSize="13px"
                fontWeight={location.pathname.includes("tools/mobility") ? "700" : "400"}
                color={location.pathname.includes("tools/mobility") ? theme.palette.primary.main : ""}
                style={{ transition: "all ease 100ms" }}
              >
                Mon appli Mobility
              </Text>
            </Box>
            <Box height="30px" width="100%" minHeight="30px" justifyContent="flex-end" pl={3} onClick={() => navigate("/tools/connexion")}>
              <Text
                textAlign="right"
                fontSize="13px"
                fontWeight={location.pathname.includes("tools/connexion") ? "700" : "400"}
                color={location.pathname.includes("tools/connexion") ? theme.palette.primary.main : ""}
                style={{ transition: "all ease 100ms" }}
              >
                Connexion à distance
              </Text>
            </Box>
            <Box height="30px" width="100%" minHeight="40px" justifyContent="flex-end" pl={3} pb={2} onClick={() => navigate("/tools/coficoffre")}>
              <Text
                textAlign="right"
                fontSize="13px"
                fontWeight={location.pathname.includes("tools/coficoffre") ? "700" : "400"}
                color={location.pathname.includes("tools/coficoffre") ? theme.palette.primary.main : ""}
                style={{ transition: "all ease 100ms" }}
              >
                Mes données sécuriées
              </Text>
            </Box>
          </Box>
        </NavLink>
        <Box height="1px" width="100%" bgcolor="#d5d5d5" />
      </Box>
      <Profile />
    </Box>
  );
}

function Profile() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser, getUser } = useSystemContext();
  const user = getUser();
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const navigationProfile = () => {
    const period = new Date(new Date().setDate(new Date().getDate()));
    period.setHours(0, 0);
    let to = qs.stringify({ period: period.getTime() }, { arrayFormat: "index" });
    navigate(`/profile?${to}`);
  };

  return (
    <Box justifyContent="center" alignItems="center" flexDirection="column">
      <Box justifyContent="center" alignItems="center" pb={4} onClick={navigationProfile} style={{ cursor: "pointer" }}>
        <Text
          textAlign="center"
          fontSize="12px"
          fontWeight={location.pathname.includes("profile") ? "700" : "400"}
          color={location.pathname.includes("profile") ? theme.palette.primary.main : ""}
        >
          Mes informations
        </Text>
      </Box>
      <Box justifyContent="center" alignItems="center" pb={4} style={{ cursor: "pointer" }}>
        <Text
          color={theme.palette.primary.main}
          textAlign="center"
          fontSize="12px"
          style={{ textDecoration: "underline" }}
          onClick={() => setOpenModal(true)}
        >
          Contacter mon agence
        </Text>
        <RenderModal onClose={() => setOpenModal(false)} open={openModal} user={user} />
      </Box>
      <Button
        onClick={async () => {
          navigate("/welcome");
          setIdToken("");
          setUser("");
          setAccessToken("");
          setRefreshToken("");
        }}
      >
        Déconnexion
      </Button>
    </Box>
  );
}

const RenderModal = ({ open, onClose, user }: { open: boolean; onClose: any; user }) => {
  const theme = useTheme();

  return (
    <Modal open={open} onClose={onClose}>
      <Box width="850px" maxWidth="98%" mx="auto" my={5} flexDirection="row" height="100%" alignItems="center">
        <Box width="50%" flexDirection="column" p={3}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Text
              style={{
                fontSize: 30,
                fontWeight: 500,
                color: theme.colors.primary,
                textAlign: "center",
              }}
            >
              Besoin de renseignements ?
            </Text>
            <Box p={2} />
            <Text color={theme.palette.grey[500]} fontSize={15} fontWeight="700" textAlign="center">
              Contactez votre agence Cofidoc {user?.codeAgence}
            </Text>
            <Box p={2} />

            <Text textAlign="center" mt={2}>
              <a href="tel:0806080808" style={{ color: theme.colors.primary }}>
                08 06 08 08 08
              </a>
            </Text>
          </Box>
        </Box>
        <Box width="50%" height="250px">
          <div className="image-cofidoc-metier" />
        </Box>
        <Box
          onClick={onClose}
          style={{
            cursor: "pointer",
            position: "absolute",
            bottom: 15,
            right: 30,
          }}
        >
          <Text color={theme.palette.primary.main} textAlign="center" fontWeight="700">
            FERMER
          </Text>
        </Box>
      </Box>
    </Modal>
  );
};
