import { Box, Text } from "../../../ui";
import { Card } from "../../../ui/Card";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { useSystemContext } from "@/provider/common/SystemProvider";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";

import ArticleIcon from "@mui/icons-material/Article";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function WelcomeView() {
  const theme = useTheme();
  const { isMobile } = useSystemContext();
  const navigate = useNavigate();

  return (
    <Box height="100vh" justifyContent="center" alignItems="center" width="100vw" style={{ backgroundColor: "#f1f1f1" }}>
      <div className="background-cloud height40" />
      <div className="background-over" />
      <div className="background-mountain-cofidoc" />
      <Box display="flex" p={2} alignItems="center" justifyContent="center" zIndex={2} position="absolute" top={20} left={20}>
        <div className="background-logo" />
      </Box>
      <Box width="100%" style={{ position: "relative", zIndex: 2, justifyContent: "center", alignItems: "center" }}>
        <Card justifyContent="center" alignItems="center" display="flex" flexDirection="column" width={isMobile ? "90%" : "650px"}>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Box style={{ margin: 20 }} width="100%" justifyContent="center" alignItems="center">
              <Text fontSize={35} fontWeight={500} textAlign="center" width="50%">
                Bienvenue sur votre espace web <span style={{ color: theme.colors.primary }}>Cofidoc</span>
              </Text>
            </Box>
          </Box>
          <Box
            flexDirection="row"
            width="80%"
            style={{ marginBottom: 20, border: `solid 1px ${theme.palette.primary.main}20`, cursor: "pointer" }}
            borderRadius={4}
            overflow="hidden"
          >
            <Box flexDirection="column" alignItems="center" justifyContent="center" bgcolor={`${theme.palette.primary.main}20`} p={4}>
              <PhoneIphoneIcon style={{ color: theme.palette.primary.main }} />
              <Box p={1} />
              <LaptopMacIcon style={{ color: theme.palette.primary.main }} />
            </Box>

            <Box flexDirection="column" className="hoverWelcomeButton" onClick={() => navigate("/login")} p={4}>
              <Text fontSize={18}>
                J'ai un compte sur mon application <span style={{ color: theme.palette.primary.main }}>Cofidoc Taxi</span> ou{" "}
                <span style={{ color: theme.palette.primary.main }}>Cofidoc Mobility</span>
              </Text>
              <Box alignItems="center" display="flex" p={1}>
                <ArrowForwardIcon style={{ fontSize: "16px", marginRight: "5px", color: theme.palette.primary.dark }} />
                <Text fontSize={16} color={theme.palette.primary.dark}>
                  Accéder au nouvel espace web
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            flexDirection="row"
            width="80%"
            style={{ border: `solid 1px ${theme.palette.primary.main}20`, cursor: "pointer", marginBottom: 40 }}
            borderRadius={4}
            overflow="hidden"
          >
            <Box flexDirection="column" alignItems="center" justifyContent="center" bgcolor={`${theme.palette.primary.main}20`} p={4}>
              <ArticleIcon style={{ color: theme.palette.primary.main }} />
              <Box p={1} />
              <MailOutlineIcon style={{ color: theme.palette.primary.main }} />
            </Box>
            <Box
              flexDirection="column"
              className="hoverWelcomeButton"
              component="a"
              //@ts-ignore
              href="https://espace-client.cofidoc.fr/user/login"
              p={4}
            >
              <Text fontSize={18}>
                Je n'ai pas encore de compte sur l'application mobile <span style={{ color: theme.palette.primary.main }}>Cofidoc Taxi</span> ou{" "}
                <span style={{ color: theme.palette.primary.main }}>Cofidoc Mobility</span>
              </Text>
              <Box alignItems="center" display="flex" p={1}>
                <ArrowForwardIcon style={{ fontSize: "16px", marginRight: "5px", color: theme.palette.primary.dark }} />
                <Text fontSize={16} color={theme.palette.primary.dark}>
                  Accéder à l'ancien espace web
                </Text>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
      <Box style={{ position: "absolute", bottom: 0, zIndex: 3, padding: 5 }}>
        <Text style={{ textAlign: "center", fontSize: 12 }}>© 2024 Cofidoc Groupe</Text>
      </Box>
    </Box>
  );
}
